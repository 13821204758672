<template>
  <v-container class="pa-0 ma-0">
    <v-card left flat class="paper px-2">
      <v-card-actions class="py-0">
        <v-spacer />
        <v-switch
          v-model="metric"
          label="Metric?"
          color="accent"
          class="mt-n4"
        />
      </v-card-actions>
      <v-card-text class="pa-0">
        <v-row dense>
          <v-col cols="12" class="display-1" :key="switchNum">
            {{ item.repscheme }}
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="item.name"
              ref="name"
              :error-messages="nameErrors"
              :counter="50"
              label="Name"
              prepend-icon="mdi-billboard"
              @input="$v.item.name.$touch()"
              @focus="$event.target.select()"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              ref="type"
              :error-messages="typeErrors"
              v-model="item.type"
              :items="repschemeTypes"
              label="Type"
              chips
              deletable-chips
              hint="What is the Type? Flat, Pyramid, Wave, etc.?"
              persistent-hint
              item-text="text"
              item-value="id"
              prepend-inner-icon="mdi-chevron-right-box"
              item-color="success"
              color="success"
              filled
              :menu-props="{
                closeOnClick: true,
                maxHeight: 250,
                transition: 'fab-transition'
              }"
              @blur="$v.item.type.$touch()"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              ref="utility"
              :error-messages="utilityErrors"
              v-model="item.utility"
              :items="utilityTypes"
              label="Utility"
              chips
              deletable-chips
              hint="What is the utility? Strength, Hypertrophy?"
              persistent-hint
              item-text="text"
              item-value="id"
              prepend-inner-icon="mdi-chevron-right-box"
              item-color="success"
              color="success"
              filled
              :menu-props="{
                closeOnClick: true,
                maxHeight: 250,
                transition: 'fab-transition'
              }"
              @blur="$v.item.utility.$touch()"
            />
          </v-col>
          <v-col cols="12" md="12">
            <tiptap-vuetify
              v-model="item.description"
              :extensions="extensions"
              :error-messages="descriptionErrors"
              placeholder="Enter a meaningful description"
            />
          </v-col>
          <v-col cols="12" class="mt-2" />
          <v-col cols="6" md="3">
            <v-text-field
              v-model="numsets"
              ref="numsets"
              class="enlarged-input-small"
              :error-messages="setsErrors"
              :counter="2"
              label="Sets"
              hint="Number of Sets"
              persistent-hint
              type="number"
              prepend-icon="mdi-dots-vertical-circle"
              @input="numsetsChanged"
              @blur="$v.numsets.$touch()"
              @focus="$event.target.select()"
            />
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              v-model="reps"
              ref="reps"
              class="enlarged-input-small"
              :error-messages="repsErrors"
              :counter="2"
              label="Reps"
              hint="Default Repetitions"
              persistent-hint
              type="number"
              prepend-icon="mdi-dots-horizontal-circle"
              @input="repsChanged"
              @blur="$v.reps.$touch()"
              @focus="$event.target.select()"
            />
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              v-model="rest"
              ref="rest"
              class="enlarged-input-small"
              :error-messages="restErrors"
              :counter="3"
              label="Rest"
              type="number"
              hint="Rest time in seconds"
              persistent-hint
              prepend-icon="mdi-clock"
              @input="restChanged"
              @blur="$v.rest.$touch()"
              @focus="$event.target.select()"
            />
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              v-model="load"
              ref="load"
              class="enlarged-input-small"
              :error-messages="loadErrors"
              :counter="2"
              label="Load"
              type="number"
              hint="Load as % of 1RM"
              persistent-hint
              prepend-icon="mdi-percent"
              @input="loadChanged"
              @blur="$v.load.$touch()"
              @focus="$event.target.select()"
            />
          </v-col>
          <v-col v-if="numsets > 0" cols="12">
            <v-card-title class="mt-1 px-0 pb-0 accent--text">
              Sets Information
            </v-card-title>
            <v-card-text class="px-0 pt-0 caption silver--text">
              Click the sets below to change the values.
            </v-card-text>
            <v-simple-table>
              <thead>
                <tr>
                  <th :class="textSizeXSmall">Set#</th>
                  <th :class="textSizeXSmall">
                    Reps & <span class="accent--text">Rest(seconds)</span>
                  </th>
                  <th :class="textSizeXSmall">
                    Load & <span class="accent--text">Increment </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="set in item.sets"
                  :key="set.value"
                  @click="showRow(set)"
                >
                  <td>
                    <v-icon color="silver" v-if="set.setnum <= 10"
                      >{{ iconNumber(set.setnum - 1) }}
                    </v-icon>
                    <span class="silver--text pl-1" v-if="set.setnum > 10">
                      {{ set.setnum }}
                    </span>
                  </td>
                  <td :class="textSizeSmall">
                    {{ set.reps }} /
                    <span class="accent--text">{{ set.rest }}</span>
                  </td>
                  <td :class="textSizeSmall">
                    {{ loadValue(set) }} /
                    <span class="accent--text"
                      >{{ set.load_increment }} {{ unit(metric) }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-0">
        <v-spacer />
        <BaseActionButton
          @clickedThis="save"
          plain
          title="Save Rep Scheme"
          :disabled="shouldDisable()"
        />
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="showEditSet"
      persistent
      width="800"
      overlay-opacity=".95"
    >
      <v-card tile flat class="noscroll d-flex flex-column">
        <BaseCloseDialogHeader
          :heading="'Edit Set # ' + selectedForEdit.setnum"
        />
        <v-card-text :class="textSizeXSmall + ' pb-4 px-2'">
          Edit the set information below then click "Done".
          <v-spacer />
          You need to
          <span class="accent--text">save the repscheme </span>for the changes
          to take effect.
        </v-card-text>
        <v-divider class="mb-5" />
        <v-card-text class="pa-0 mb-4">
          <v-row class="px-1">
            <v-col cols="6">
              <v-text-field
                class="enlarged-input-small"
                v-model="selectedForEdit.reps"
                counter="2"
                ref="reps"
                min="1"
                max="99"
                label="Reps"
                hint="Repetions"
                persistent-hint
                type="number"
                prepend-icon="mdi-dots-horizontal-circle"
                @focus="$event.target.select()"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                class="enlarged-input-small"
                min="1"
                max="600"
                counter="3"
                v-model="selectedForEdit.rest"
                hint="Rest seconds"
                persistent-hint
                ref="rest"
                label="Rest"
                type="number"
                prepend-icon="mdi-clock"
                @focus="$event.target.select()"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                class="enlarged-input-small"
                v-model="selectedForEdit.load"
                hint="% of 1RM"
                persistent-hint
                counter="3"
                min="1"
                max="100"
                label="Load (%1RM)"
                type="number"
                prepend-icon="mdi-label-percent"
                @focus="$event.target.select()"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                class="enlarged-input-small"
                v-model="selectedForEdit.load_increment"
                counter="3"
                min="1"
                max="200"
                hint="Weight to add"
                persistent-hint
                label="Load Increment"
                type="number"
                prepend-icon="mdi-weight"
                @focus="$event.target.select()"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-spacer />
        <v-card-actions class="pr-1 pl-0 ml-n2 py-1 lightHeader">
          <BaseActionButton
            icon="mdi-close"
            label="Cancel"
            color="charcoal"
            text
            @clickedThis="showEditSet = false"
          />
          <v-spacer />
          <BaseActionButton
            icon="mdi-checkbox-outline"
            text
            label="Done"
            class="charcoal paper--text"
            @clickedThis="done"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { appConfig } from '@/store/helpers.js'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import axios from 'axios'
import utilityTypes from '@/json/utilitytypes.json'
import repschemeTypes from '@/json/repschemetypes.json'
import util from '@/mixins/util.js'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
  HorizontalRule
} from 'tiptap-vuetify'

export default {
  components: { TiptapVuetify },
  mixins: [util, validationMixin],
  name: 'Tools',
  data: () => ({
    metric: false,
    utilityTypes: utilityTypes,
    repschemeTypes: repschemeTypes,
    filter: 'all',
    item: {
      name: 'Enter repscheme name',
      description: '',
      type: null,
      utility: null
    },
    selectedForEdit: {},
    numsets: 0,
    reps: 0,
    rest: 0,
    load: 0,
    loaded: false,
    showEditSet: false,
    switchNum: 0,
    search: '',
    headers: [
      {
        text: 'Rep Schemes',
        sortable: false,
        value: 'name',
        class: 'accent--text display-1'
      }
    ],
    extensions: [
      Paragraph,
      Bold,
      Italic,
      [
        Heading,
        {
          options: {
            levels: [5, 6]
          }
        }
      ],
      HorizontalRule,
      ListItem,
      BulletList,
      OrderedList,
      HardBreak
    ]
  }),
  validations: {
    item: {
      name: {
        required
      },
      description: {
        required
      },
      type: {
        required
      },
      utility: {
        required
      }
    },
    numsets: {
      required
    },
    reps: {
      required
    },
    rest: {
      required
    },
    load: {
      required
    }
  },
  beforeMount() {},
  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.item.name.$dirty) return errors
      !this.$v.item.name.required && errors.push('Exercise name is required.')
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.item.description.$dirty) return errors
      !this.$v.item.description.required &&
        errors.push('Description is required.')
      return errors
    },
    typeErrors() {
      const errors = []
      if (!this.$v.item.type.$dirty) return errors
      !this.$v.item.type.required && errors.push('Type is required.')
      return errors
    },
    utilityErrors() {
      const errors = []
      if (!this.$v.item.utility.$dirty) return errors
      !this.$v.item.utility.required && errors.push('Utility is required.')
      return errors
    },
    setsErrors() {
      const errors = []
      if (!this.$v.numsets.$dirty) return errors
      !this.$v.numsets.required &&
        errors.push('The number of sets is required.')
      return errors
    },
    repsErrors() {
      const errors = []
      if (!this.$v.reps.$dirty) return errors
      !this.$v.reps.required && errors.push('The number of reps is required.')
      return errors
    },
    restErrors() {
      const errors = []
      if (!this.$v.rest.$dirty) return errors
      !this.$v.rest.required && errors.push('The number of rest is required.')
      return errors
    },
    loadErrors() {
      const errors = []
      if (!this.$v.load.$dirty) return errors
      !this.$v.load.required && errors.push('Load is required.')
      return errors
    },
    ...appConfig
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  methods: {
    shouldDisable() {
      if (
        this.sets <= 0 ||
        this.reps <= 0 ||
        this.item.name == '' ||
        this.item.description == '' ||
        this.item.type == null ||
        this.item.utility == null ||
        this.$v.$anyError
      )
        return true
      else return false
    },
    repsChanged() {
      this.generateSets()
      this.$v.reps.$touch()
    },
    numsetsChanged() {
      this.generateSets()
      this.$v.numsets.$touch()
    },
    restChanged() {
      this.generateSets()
      this.$v.rest.$touch()
    },
    loadChanged() {
      this.generateSets()
      this.$v.load.$touch()
    },
    done() {
      this.calculateRepScheme()
      this.switchNum++
      this.showEditSet = false
    },
    calculateRepScheme() {
      let rs = ''
      if (this.isEmpty(this.item.sets)) {
        this.item.repscheme = rs
        return
      }

      this.item.sets.forEach(set => {
        rs = rs.concat(set.reps.toString() + '-')
      })
      this.item.repscheme = rs.slice(0, -1)
    },
    showRow(set) {
      this.showEditSet = true
      this.selectedForEdit = set
    },
    generateSets() {
      if (this.numsets > 0 && this.reps > 0) {
        this.item.sets = []
        for (let i = 1; i <= this.numsets; i++) {
          let set = {
            setnum: i,
            reps: this.reps,
            rest: this.rest,
            load: this.load,
            load_imperial: this.load,
            load_metric: this.load,
            load_type: '1RM',
            load_increment: 0
          }
          this.item.sets.push(set)
        }
        this.calculateRepScheme()
      }
    },
    loadValue(set) {
      if (set.load_type == '1RM') return set.load + '%'
      else return set.load_imperial + 'lbs / ' + set.load_metric + ' Kg'
    },
    save() {
      return axios
        .post(this.baseURL + '/tools/repschemes', {
          item: this.item
        })
        .then(response => {
          if (response.status == 200) {
            this.$router.push('/repschemes')
            this.$store.dispatch(
              'notification/addNotification',
              'Rep scheme was succesfully added.',
              2000,
              true,
              {
                root: true
              }
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
